import React from 'react'

function Advance() {
  return (
    <div className="flex items-center justify-center h-screen">
    <h1 className="text-3xl font-extrabold">Coming Soon...</h1>
  </div>
  )
}

export default Advance